import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const Hint = ({ children, ...restProps }) => {
  return (
    <Box m={2} textAlign="center" color="text.hint" {...restProps}>
      {children}
    </Box>
  );
};

Hint.propTypes = {
  children: PropTypes.node,
};

export default Hint;
