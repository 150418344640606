const isAuthTokenValid = (token) => {
	if (typeof token !== "string") {
		return false;
	}

	const [, payloadPart] = token.split(".");

	if (!payloadPart) {
		return false;
	}

	const { exp } = JSON.parse(atob(payloadPart));

	if (typeof exp !== "number") {
		return false;
	}

	return new Date().getTime() < exp * 1000;
};

export default isAuthTokenValid;
