import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';
import Sticky from 'react-stickynode';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import useInfiniteLoader from '../../_to_be_published/hooks/useInfiniteLoader';
import useRestState from '../../_to_be_published/hooks/useRestState';
import useDialog from '../../_to_be_published/hooks/useDialog';

import SelectInput from '../../_to_be_published/components/SelectInput';
import ButtonSlim from '../../_to_be_published/components/ButtonSlim';
import Hint from '../../_to_be_published/components/Hint';
import ConfirmationDialog from '../../_to_be_published/components/ConfirmationDialog';

import rest, { endpoints } from '../../utils/rest';

import Coupon from './components/Coupon';

const START_ISSUE = 1;
const END_ISSUE = 12;
const ISSUE_OPTIONS = [];
for (let i = START_ISSUE; i <= END_ISSUE; i++) {
  ISSUE_OPTIONS.push(i);
}

const START_YEAR = 2020;
const CURRENT_YEAR = new Date().getFullYear();
const YEAR_OPTIONS = [];
for (let i = START_YEAR; i <= CURRENT_YEAR + 1; i++) {
  YEAR_OPTIONS.push(i);
}

const SORTING_OPTIONS = [
  { value: 'desc', title: 'Naujausi viršuje' },
  { value: 'asc', title: 'Seniausi viršuje' },
];

const MAGAZINES_URL = endpoints.magazines;
const COUPONS_URL = endpoints.coupons;
const COUPONS_COUNT_URL = `${COUPONS_URL}/count`;

const COUPON_BATCH_SIZE = 10;

// TODO rename this to "view" or something, as it filters not only by magazine
const MAGAZINE_ID_UNRECOGNIZED = 'unrecognized';
const MAGAZINE_ID_SAVED = 'saved';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  controls: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  control: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  countView: {
    marginTop: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  coupons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const Coupons = () => {
  const classes = useStyles();

  const headerRef = useRef();
  const [headerTopOffset, setHeaderTopOffset] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const removeSelectedConfirmationDialog = useDialog();

  const magazines = useRestState();

  const [magazine, setMagazine] = useState('');
  const [magazineIssue, setMagazineIssue] = useState('');
  const [magazineYear, setMagazineYear] = useState(CURRENT_YEAR);
  const [sorting, setSorting] = useState(SORTING_OPTIONS[0].value);

  const [selectedCouponsMap, setSelectedCouponsMap] = useState({});

  const coupons = useRestState();
  const couponCount = useRestState();

  const displayGenericRestError = useCallback(() => {
    enqueueSnackbar('Sistemos klaida. Kreipkitės į sistemos administratorių.', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const makeCouponsQuery = useCallback(
    (limit, start) => {
      const query = {
        _limit: limit,
        _start: start,
        _sort: `created_at:${sorting}`,
      };

      const _magazineIssue = magazineIssue || undefined;

      if (magazine) {
        if (magazine.id === MAGAZINE_ID_UNRECOGNIZED) {
          query['magazine_null'] = true;
        } else {
          query['magazineIssue'] = _magazineIssue;
          query['magazineYear'] = magazineYear;

          if (magazine.id === MAGAZINE_ID_SAVED) {
            query['saved'] = true;
          } else {
            query['magazine'] = magazine.id;
          }
        }
      } else {
        query['magazine_null'] = false;
        query['magazineIssue'] = _magazineIssue;
        query['magazineYear'] = magazineYear;
      }

      return query;
    },
    [magazine, magazineIssue, magazineYear, sorting]
  );

  useEffect(() => {
    if (headerRef.current) {
      const { top } = headerRef.current.getBoundingClientRect();
      setHeaderTopOffset(top);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await rest.get(
          MAGAZINES_URL,
          { params: { _sort: 'magazineId:asc' } },
          {
            ...magazines.callbacks,
            setLoaded: (data) =>
              magazines.callbacks.setLoaded([
                ...data,
                { id: MAGAZINE_ID_UNRECOGNIZED, title: 'Neatpažinti' },
                { id: MAGAZINE_ID_SAVED, title: 'Išsaugoti' },
              ]),
          }
        );
      } catch (error) {
        displayGenericRestError();
      }
    })();
  }, [magazines.callbacks, displayGenericRestError]);

  useEffect(() => {
    (async () => {
      try {
        await rest.get(
          COUPONS_COUNT_URL,
          { params: makeCouponsQuery() },
          couponCount.callbacks
        );
      } catch (error) {
        displayGenericRestError();
      }
    })();
  }, [makeCouponsQuery, couponCount.callbacks, displayGenericRestError]);

  useEffect(() => {
    (async () => {
      try {
        await rest.get(
          COUPONS_URL,
          { params: makeCouponsQuery(COUPON_BATCH_SIZE) },
          coupons.callbacks
        );
      } catch (error) {
        displayGenericRestError();
      }
    })();
  }, [makeCouponsQuery, coupons.callbacks, displayGenericRestError]);

  const handleSelectMagazine = ({ target: { value: magazineId } }) => {
    const magazine = magazines.data.find(({ id }) => id === magazineId);
    setMagazine(magazine);
  };

  const handleSelectMagazineIssue = ({ target: { value } }) =>
    setMagazineIssue(value);

  const handleSelectMagazineYear = ({ target: { value } }) =>
    setMagazineYear(value);

  const handleSelectSorting = ({ target: { value } }) => setSorting(value);

  const handleLoadMoreCoupons = useCallback(async () => {
    try {
      const couponBatch = await rest.get(COUPONS_URL, {
        params: makeCouponsQuery(COUPON_BATCH_SIZE, coupons.data.length),
      });
      coupons.callbacks.setLoaded([...coupons.data, ...couponBatch]);
    } catch (error) {
      displayGenericRestError();
    }
  }, [
    makeCouponsQuery,
    coupons.data,
    coupons.callbacks,
    displayGenericRestError,
  ]);

  const { loaderRef, loading: loadingMoreCoupons } = useInfiniteLoader(
    handleLoadMoreCoupons
  );

  const handleSave = async (id, saved) => {
    try {
      await rest.put(`${endpoints.coupons}/${id}`, { saved });
      const couponIndex = coupons.data.findIndex((coupon) => coupon.id === id);
      if (magazine?.id === MAGAZINE_ID_SAVED) {
        coupons.callbacks.setLoaded([
          ...coupons.data.slice(0, couponIndex),
          ...coupons.data.slice(couponIndex + 1),
        ]);
        couponCount.callbacks.setLoaded(couponCount.data - 1);
      } else {
        coupons.callbacks.setLoaded([
          ...coupons.data.slice(0, couponIndex),
          { ...coupons.data[couponIndex], saved },
          ...coupons.data.slice(couponIndex + 1),
        ]);
      }
    } catch (error) {
      displayGenericRestError();

      throw error;
    }
  };

  const handleRemove = async (id) => {
    try {
      await rest.del(`${endpoints.coupons}/${id}`);
      const couponIndex = coupons.data.findIndex((coupon) => coupon.id === id);
      coupons.callbacks.setLoaded([
        ...coupons.data.slice(0, couponIndex),
        ...coupons.data.slice(couponIndex + 1),
      ]);
      couponCount.callbacks.setLoaded(couponCount.data - 1);
    } catch (error) {
      displayGenericRestError();
      throw error;
    }
  };

  const handleSelect = (id, selected) =>
    setSelectedCouponsMap((state) => ({ ...state, [id]: selected }));

  const handleSelectAllClick = () => {
    const allSelected = coupons.data.every(({ id }) => selectedCouponsMap[id]);
    const select = !allSelected;

    const newSelectedCouponsMap = coupons.data.reduce(
      (selectedCouponsMap, { id }) => {
        selectedCouponsMap[id] = select;
        return selectedCouponsMap;
      },
      {}
    );

    setSelectedCouponsMap(newSelectedCouponsMap);
  };

  const handleRemoveSelectedConfirm = async () => {
    const removeCouponIds = Object.keys(selectedCouponsMap).filter(
      (id) => selectedCouponsMap[id]
    );
    try {
      await Promise.all(
        removeCouponIds.map((id) => rest.del(`${endpoints.coupons}/${id}`))
      );

      const nonRemovedCoupons = coupons.data.filter(
        ({ id }) => !selectedCouponsMap[id]
      );

      coupons.callbacks.setLoaded(nonRemovedCoupons);
      couponCount.callbacks.setLoaded(
        couponCount.data - removeCouponIds.length
      );

      const newSelectedCouponsMap = nonRemovedCoupons.reduce(
        (selectedCouponsMap, { id }) => {
          selectedCouponsMap[id] = false;
          return selectedCouponsMap;
        },
        {}
      );
      setSelectedCouponsMap(newSelectedCouponsMap);
    } catch (error) {
      displayGenericRestError();
      throw error;
    }
  };

  const handleRemoveSelectedClick = () =>
    removeSelectedConfirmationDialog.setOpen(true);

  return (
    <div>
      <Sticky innerZ={1} top={headerTopOffset}>
        <Paper ref={headerRef} className={classes.header}>
          <div className={classes.controls}>
            {magazines.data && (
              <SelectInput
                className={classes.control}
                label="Pasirinkite žurnalą"
                value={magazine && magazine.id}
                disabled={coupons.loading || couponCount.loading}
                onChange={handleSelectMagazine}
              >
                <MenuItem value="">Visi atpažinti</MenuItem>
                {magazines.data.map(({ id, magazineId, title }) => (
                  <MenuItem key={id} value={id}>
                    {magazineId} {title}
                  </MenuItem>
                ))}
              </SelectInput>
            )}
            {(!magazine || magazine.id !== MAGAZINE_ID_UNRECOGNIZED) && (
              <>
                <SelectInput
                  className={classes.control}
                  label="Pasirinkite žurnalo numerį"
                  value={magazineIssue}
                  disabled={coupons.loading || couponCount.loading}
                  onChange={handleSelectMagazineIssue}
                >
                  <MenuItem value="">Visi</MenuItem>
                  {ISSUE_OPTIONS.map((issue) => (
                    <MenuItem key={issue} value={issue}>
                      {issue}
                    </MenuItem>
                  ))}
                </SelectInput>
                <SelectInput
                  className={classes.control}
                  label="Pasirinkite metus"
                  value={magazineYear}
                  disabled={coupons.loading || couponCount.loading}
                  onChange={handleSelectMagazineYear}
                >
                  {YEAR_OPTIONS.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </SelectInput>
              </>
            )}
            <SelectInput
              className={classes.control}
              label="Rikiavimas"
              value={sorting}
              disabled={coupons.loading || couponCount.loading}
              onChange={handleSelectSorting}
            >
              {SORTING_OPTIONS.map(({ value, title }, index) => (
                <MenuItem key={index} value={value}>
                  {title}
                </MenuItem>
              ))}
            </SelectInput>
          </div>
          {!couponCount.loading && !!couponCount.data && (
            <Typography className={classes.countView}>
              Viso kuponų: {couponCount.data}
            </Typography>
          )}
          <div className={classes.actions}>
            {Object.values(selectedCouponsMap).some((selected) => selected) && (
              <ButtonSlim onClick={handleRemoveSelectedClick}>
                Pašalinti pasirinktus
              </ButtonSlim>
            )}
            {coupons.data &&
              !coupons.loading &&
              !!couponCount.data &&
              !couponCount.loading &&
              couponCount.data > 0 && (
                <ButtonSlim onClick={handleSelectAllClick}>
                  Pasirinkti visus
                </ButtonSlim>
              )}
          </div>
        </Paper>
      </Sticky>
      <div className={classes.coupons}>
        {typeof coupons.data === 'undefined' ||
        coupons.loading ||
        typeof couponCount.data === 'undefined' ||
        couponCount.loading ? (
          <CircularProgress />
        ) : couponCount.data > 0 ? (
          <>
            {coupons.data.map((coupon) => (
              <Coupon
                key={coupon.id}
                selected={selectedCouponsMap[coupon.id]}
                onSave={handleSave}
                onRemove={handleRemove}
                onSelect={handleSelect}
                {...coupon}
              />
            ))}
            {coupons.data.length < couponCount.data &&
              (loadingMoreCoupons ? (
                <CircularProgress />
              ) : (
                <div ref={loaderRef} />
              ))}
          </>
        ) : (
          <Hint textAlign="left">
            <Typography>Nėra kuponų</Typography>
          </Hint>
        )}
      </div>
      <ConfirmationDialog
        {...removeSelectedConfirmationDialog.props}
        text="Ar tikrai norite pašalinti pasirinktus kuponus?"
        onConfirm={handleRemoveSelectedConfirm}
      />
    </div>
  );
};

export default Coupons;
