// NOTE add auth token to every request

export const register = (axiosInstance, skipUrls, authToken) => {
  const interceptor = axiosInstance.interceptors.request.use((config) => {
    // NOTE do not add token if it's auth request, because expired token would
    // cause it to fail
    if (skipUrls.includes(config.url)) {
      return config;
    }

    const configWithAuth = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
      },
    };

    return configWithAuth;
  });

  return interceptor;
};

export const unregister = (axiosInstance, interceptor) =>
  axiosInstance.interceptors.request.eject(interceptor);
