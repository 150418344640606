import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ value, ...restProps }) => {
  return <TextField variant="outlined" value={value || ''} {...restProps} />;
};

TextInput.propTypes = {
  vaule: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextInput;
