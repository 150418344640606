import React from 'react';
import { fieldToTextField } from 'formik-material-ui';

import TextInput from '../TextInput';

const TextField = (props) => {
  return <TextInput {...fieldToTextField(props)} variant="outlined" />;
};

export default TextField;
