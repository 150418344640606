import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Image = ({ placeholder, onLoad, ...restProps }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const imageBlob = new Blob([await onLoad()]);
        const imageURL = URL.createObjectURL(imageBlob);
        setImageURL(imageURL);
      } catch (error) {}
    })();
  }, [onLoad]);

  return imageURL ? <img alt="" {...restProps} src={imageURL} /> : placeholder;
};

Image.defaultProps = {
  // NOTE if this was undefined, react would complain that "nothing" is returned
  // from render, as it expects null to indicate nothing to render
  placeholder: null,
};

Image.propTypes = {
  placeholder: PropTypes.node,
  onLoad: PropTypes.func.isRequired,
};

export default Image;
