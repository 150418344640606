import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import useRestState from '../../../../../../_to_be_published/hooks/useRestState';

import ImageComponent from '../../../../../../_to_be_published/components/Image';

import rest, { endpoints } from '../../../../../../utils/rest';

import imagePartNames from '../../../../utils/imagePartNames';

const COUPONS_URL = endpoints.coupons;

const Image = ({ couponId, part, thumbnail, ...restProps }) => {
  const image = useRestState();

  const handleLoadImage = useCallback(async () => {
    const data = await rest.get(
      `${COUPONS_URL}/${couponId}/image/${part}${
        thumbnail ? '?thumbnail=true' : ''
      }`,
      {
        responseType: 'blob',
      },
      image.callbacks
    );
    return data;
  }, [couponId, part, thumbnail, image.callbacks]);

  return (
    <ImageComponent
      {...restProps}
      onLoad={handleLoadImage}
      placeholder={image.loading && <CircularProgress />}
    />
  );
};

Image.defaultProps = {
  thumbnail: false,
};

Image.propTypes = {
  couponId: PropTypes.number.isRequired,
  part: PropTypes.oneOf(Object.values(imagePartNames)).isRequired,
  thumbnail: PropTypes.bool,
};

export default Image;
