import * as Yup from 'yup';

import password from '../../../_to_be_published/utils/validation-schemas/password';

export default Yup.object().shape({
  password,
  passwordConfirmation: Yup.mixed().test(
    'passwords-match',
    'Slaptažodžiai nesutampa',
    (value, context) => value === context.parent.password
  ),
});
