import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/AccountCircle';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  userIcon: {
    marginRight: theme.spacing(1),
  },
  name: {
    marginRight: theme.spacing(1),
  },
}));

const AuthUser = ({ name, onSignOut }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {name && (
        <>
          <UserIcon className={classes.userIcon} />
          <Typography className={classes.name}>{name}</Typography>
        </>
      )}
      <Tooltip title="Atjungti naudotoją iš sistemos">
        <IconButton onClick={onSignOut} color="inherit">
          <SignOutIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

AuthUser.propTypes = {
  name: PropTypes.string,
  onSignOut: PropTypes.func.isRequired,
};

export default AuthUser;
