import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import ButtonSlim from '../ButtonSlim';

const ConfirmationDialog = ({ text, open, onConfirm, onCancel, onClose }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    try {
      setLoading(true);
      await onConfirm();
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    onClose();
    onCancel && onCancel();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress />
        ) : (
          <ButtonSlim disabled={loading} onClick={handleConfirmClick}>
            Taip
          </ButtonSlim>
        )}
        <ButtonSlim disabled={loading} onClick={handleCancelClick}>
          Atšaukti
        </ButtonSlim>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
