import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useInfiniteLoader = (onLoad) => {
  const [loading, setLoading] = useState(false);

  const { ref: loaderRef, inView } = useInView();

  useEffect(() => {
    (async () => {
      if (inView && !loading) {
        setLoading(true);
        await onLoad();
        setLoading(false);
      }
    })();
  }, [inView, loading, onLoad]);

  return { loaderRef, loading };
};

export default useInfiniteLoader;
