import { useState } from 'react';

const useDialog = () => {
  const [state, setState] = useState({
    props: {
      open: false,
      onClose: () =>
        setState((state) => ({
          ...state,
          props: { ...state.props, open: false },
        })),
    },
    setOpen: (open) =>
      setState((state) => ({
        ...state,
        props: { ...state.props, open },
      })),
  });

  return state;
};

export default useDialog;
