import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import drawerWidth from '../../utils/drawerWidth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      // NOTE on bigger screens permanent navigation drawer is displayed on the
      // left
      marginLeft: ({ enableMenu }) => enableMenu && drawerWidth,
    },
  },
  // NOTE necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const AppContent = ({ enableMenu, children }) => {
  const classes = useStyles({ enableMenu });

  return (
    <main className={classes.root}>
      <div className={classes.toolbar} />
      {children}
    </main>
  );
};

AppContent.defaultProps = {
  enableMenu: false,
};

AppContent.propTypes = {
  enableMenu: PropTypes.bool,
  children: PropTypes.node,
};

export default AppContent;
