import { useState } from 'react';

const defaultState = {
  loading: undefined,
  data: undefined,
  error: undefined,
};

const useRestState = () => {
  const [state, setState] = useState({
    ...defaultState,
    callbacks: {
      setLoading: () => setState((state) => ({ ...state, loading: true })),
      setLoaded: (data) =>
        setState((state) => ({
          ...state,
          loading: false,
          data,
          error: undefined,
        })),
      setErrored: (error) =>
        setState((state) => ({ ...state, loading: false, error })),
    },
  });

  return state;
};

export default useRestState;
