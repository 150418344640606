import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import * as axiosRequestAuthInterceptor from "./_to_be_published/utils/axiosRequestAuthInterceptor";
import isAuthTokenValid from "./_to_be_published/utils/isAuthTokenValid";

import AppBar from "./_to_be_published/components/AppBar";
import AppContent from "./_to_be_published/components/AppContent";
import { useAuthContext } from "./_to_be_published/components/AuthProvider";
import AuthUser from "./_to_be_published/components/AuthUser";

import { endpoints } from "./utils/rest";

import axiosInstance from "./axiosInstance";

import Coupons from "./components/Coupons";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import SignIn from "./components/SignIn";
import PointsTable from "./components/Table";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	Switch,
	Tooltip,
} from "@material-ui/core";
import ExitToApp from "@material-ui/icons/ExitToApp";

const App = () => {
	const {
		state: { token: authToken },
		signIn,
		signOut,
	} = useAuthContext();

	const [authConfigured, setAuthConfigured] = useState(false);
	const [showTable, setShowTable] = useState(false);

	const authTokenValid = isAuthTokenValid(authToken);

	useEffect(() => {
		if (!authToken) {
			return;
		}

		const interceptor = axiosRequestAuthInterceptor.register(
			axiosInstance,
			[endpoints.auth, endpoints.forgotPassword, endpoints.resetPassword],
			authToken
		);
		setAuthConfigured(true);

		return () => {
			axiosRequestAuthInterceptor.unregister(axiosInstance, interceptor);
		};
	}, [authToken]);

	const handleSignIn = (data) => {
		setAuthConfigured(false);
		signIn(data);
	};

	return authTokenValid ? (
		authConfigured ? (
			<>
				<AppBar
					text={`Oho Kuponai`}
					actions={<AuthUser onSignOut={signOut} />}
					pages={
						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										onChange={() =>
											setShowTable((prev) => !prev)
										}
									/>
								}
								label="Taškų lentelė"
							/>
						</FormGroup>
					}
				/>
				<AppContent>
					{showTable ? <PointsTable /> : <Coupons />}
				</AppContent>
			</>
		) : null
	) : (
		<Routes>
			<Route path="/" element={<SignIn onSignIn={handleSignIn} />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Routes>
	);
};

export default App;
