export const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export const request = async (requestMaker, url, method, config, callbacks) => {
  callbacks?.setLoading?.();

  try {
    const data = await requestMaker(url, method, config);

    callbacks?.setLoaded?.(data);

    return data;
  } catch (error) {
    callbacks?.setErrored?.(error);

    throw error;
  }
};

export const get = (requestMaker, url, config, callbacks) =>
  request(requestMaker, url, methods.GET, config, callbacks);

export const post = (requestMaker, url, config, callbacks) =>
  request(requestMaker, url, methods.POST, config, callbacks);

export const put = (requestMaker, url, config, callbacks) =>
  request(requestMaker, url, methods.PUT, config, callbacks);

export const del = (requestMaker, url, config, callbacks) =>
  request(requestMaker, url, methods.DELETE, config, callbacks);

export const makeRest = (requestMaker) => ({
  request: (url, method, config, callbacks) =>
    request(requestMaker, url, method, config, callbacks),
  get: (url, config, callbacks) => get(requestMaker, url, config, callbacks),
  post: (url, config, callbacks) => post(requestMaker, url, config, callbacks),
  put: (url, config, callbacks) => put(requestMaker, url, config, callbacks),
  del: (url, config, callbacks) => del(requestMaker, url, config, callbacks),
});

export const makeAxiosRest = (axiosInstance) =>
  makeRest(async (url, method, config) => {
    const { data } = await axiosInstance[method](url, config);
    return data;
  });
