import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import TextField from '../../_to_be_published/components/TextField';

import rest, { endpoints } from '../../utils/rest';

import formValidationSchema from './utils/formValidationSchema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const handleSubmit = async (values) => {
    try {
      await rest.post(endpoints.resetPassword, { ...values, code });
      enqueueSnackbar('Slaptažodis sėkmingai pakeistas.', {
        variant: 'success',
      });
      navigate('/');
    } catch ({ request, response }) {
      if (response) {
        enqueueSnackbar(
          'Sistemos klaida. Kreipkitės į sistemos administratorių.',
          { variant: 'error' }
        );
      } else if (request) {
        enqueueSnackbar(
          'Nepavyko pakeisti slaptažodžio. Gali būti, kad prastas interneto ryšys.',
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          'Sistemos klaida. Kreipkitės į sistemos administratorių.',
          { variant: 'error' }
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <div className={classes.root}>
          <Form className={classes.form}>
            <Field
              className={classes.field}
              component={TextField}
              type="password"
              name="password"
              label="Naujas slaptažodis"
            />
            <Field
              className={classes.field}
              component={TextField}
              type="password"
              name="passwordConfirmation"
              label="Pakartokite slaptažodį"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Keisti slaptažodį
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ResetPassword;
