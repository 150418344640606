import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const SelectInput = ({
  label,
  value,
  helperText,
  onChange,
  children,
  ...restProps
}) => {
  return (
    <FormControl variant="outlined" {...restProps}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value || ''} onChange={onChange}>
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default SelectInput;
