import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import useDialog from '../../../../_to_be_published/hooks/useDialog';

import imagePartNames from '../../utils/imagePartNames';

import Image from './components/Image';
import Header from './components/Header';
import FullViewDialog from './components/FullViewDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  image: {
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Coupon = ({
  id,
  created_at,
  sender,
  saved,
  selected,
  onSave,
  onRemove,
  onSelect,
}) => {
  const classes = useStyles();

  const [mouseOver, setMouseOver] = useState(false);

  const fullViewDialog = useDialog();

  const handleMouseEnter = () => setMouseOver(true);

  const handleMouseLeave = () => setMouseOver(false);

  const handleClick = () => fullViewDialog.setOpen(true);

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
  };

  const handleSelect = (event) => {
    onSelect(id, event.target.checked);
  };

  return (
    <>
      <div
        className={classes.root}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={classes.header}>
          <Header sender={sender} created_at={created_at} />
          {(selected || mouseOver) && (
            <Checkbox
              checked={selected}
              onClick={handleCheckboxClick}
              onChange={handleSelect}
            />
          )}
        </div>
        <Image
          className={classes.image}
          alt="pirmos kupono pusės nuotrauka"
          couponId={id}
          part={imagePartNames.FIRST}
          thumbnail
        />
        <Image
          className={classes.image}
          alt="antros kupono pusės nuotrauka"
          couponId={id}
          part={imagePartNames.SECOND}
          thumbnail
        />
      </div>
      <FullViewDialog
        id={id}
        created_at={created_at}
        sender={sender}
        saved={saved}
        onSave={onSave}
        onRemove={onRemove}
        {...fullViewDialog.props}
      />
    </>
  );
};

Coupon.defaultProps = {
  saved: false,
  selected: false,
};

Coupon.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  saved: PropTypes.bool,
  selected: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Coupon;
