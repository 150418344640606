import React from "react";
import { Link as RRLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link as MUILink } from "@material-ui/core";

import TextField from "../../_to_be_published/components/TextField";

import rest, { endpoints } from "../../utils/rest";

import formValidationSchema from "./utils/formValidationSchema";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginTop: theme.spacing(3),
	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: theme.spacing(1),
	},
	field: {
		marginBottom: theme.spacing(1),
	},
}));

const SignIn = ({ onSignIn }) => {
	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = async (values) => {
		try {
			const { jwt: token } = await rest.post(endpoints.auth, values);
			onSignIn(token);
		} catch ({ request, response }) {
			if (response) {
				if (response.status === 400) {
					enqueueSnackbar(
						"Neteisingas prisijungimo vardas arba slaptažodis.",
						{
							variant: "error",
						}
					);
				} else {
					enqueueSnackbar(
						"Sistemos klaida. Kreipkitės į sistemos administratorių.",
						{ variant: "error" }
					);
				}
			} else if (request) {
				enqueueSnackbar(
					"Nepavyko prisijungti. Gali būti, kad prastas interneto ryšys.",
					{ variant: "error" }
				);
			} else {
				enqueueSnackbar(
					"Sistemos klaida. Kreipkitės į sistemos administratorių.",
					{ variant: "error" }
				);
			}
		}
	};

	return (
		<Formik
			initialValues={{
				identifier: "",
				password: "",
			}}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<div className={classes.root}>
					<Form className={classes.form}>
						<Field
							className={classes.field}
							component={TextField}
							type="text"
							name="identifier"
							label="Prisijungimo vardas arba el. pašto adresas"
						/>
						<Field
							className={classes.field}
							component={TextField}
							type="password"
							name="password"
							label="Slaptažodis"
						/>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={isSubmitting}
						>
							Prisijungti
						</Button>
					</Form>
					<MUILink component={RRLink} to="/forgot-password">
						Pamiršau slaptažodį
					</MUILink>
				</div>
			)}
		</Formik>
	);
};

SignIn.propTypes = {
	onSignIn: PropTypes.func.isRequired,
};

export default SignIn;
