import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import useDialog from '../../../../../../_to_be_published/hooks/useDialog';

import ButtonSlim from '../../../../../../_to_be_published/components/ButtonSlim';
import InformationDialog from '../../../../../../_to_be_published/components/InformationDialog';
import ConfirmationDialog from '../../../../../../_to_be_published/components/ConfirmationDialog';

import imagePartNames from '../../../../utils/imagePartNames';

import Image from '../Image';
import Header from '../Header';

const useStyles = makeStyles({
  image: {
    width: '100%',
  },
  paper: {
    marginTop: 0,
    marginBottom: 0,
  },
  paperScrollPaper: {
    maxHeight: '100%',
  },
});

const FullViewDialog = ({
  id,
  created_at,
  sender,
  saved,
  onSave,
  onRemove,
  ...restProps
}) => {
  const classes = useStyles();

  const removeConfirmationDialog = useDialog();

  const handleSaveClick = () => onSave(id, !saved);

  const handleRemoveClick = () => removeConfirmationDialog.setOpen(true);

  const handleRemoveConfirm = async () => {
    await onRemove(id);
    restProps.onClose();
  };

  return (
    <>
      <InformationDialog
        classes={{
          paper: classes.paper,
          paperScrollPaper: classes.paperScrollPaper,
        }}
        title={<Header sender={sender} created_at={created_at} />}
        extraActions={
          <>
            <ButtonSlim
              startIcon={saved ? <StarIcon /> : <StarOutlineIcon />}
              onClick={handleSaveClick}
            >
              Išsaugoti
            </ButtonSlim>
            <ButtonSlim startIcon={<DeleteIcon />} onClick={handleRemoveClick}>
              Pašalinti
            </ButtonSlim>
          </>
        }
        maxWidth="md"
        fullWidth
        {...restProps}
      >
        <Image
          className={classes.image}
          alt="pirmos kupono pusės nuotrauka"
          couponId={id}
          part={imagePartNames.FIRST}
        />
        <Image
          className={classes.image}
          alt="antros kupono pusės nuotrauka"
          couponId={id}
          part={imagePartNames.SECOND}
        />
      </InformationDialog>
      <ConfirmationDialog
        {...removeConfirmationDialog.props}
        text="Ar tikrai norite pašalinti kuponą?"
        onConfirm={handleRemoveConfirm}
      />
    </>
  );
};

FullViewDialog.defaultProps = {
  saved: false,
};

FullViewDialog.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  saved: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FullViewDialog;
