import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import TextField from '../../_to_be_published/components/TextField';

import rest, { endpoints } from '../../utils/rest';

import formValidationSchema from './utils/formValidationSchema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      await rest.post(endpoints.forgotPassword, values);
      enqueueSnackbar(
        'Slaptažodžio keitimo instrukcijos išsiųstos nurodytu el. pašto adresu.',
        { variant: 'success' }
      );
    } catch ({ request, response }) {
      if (response) {
        if (response.status === 400) {
          enqueueSnackbar('Neteisingas el. pašto adresas.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(
            'Sistemos klaida. Kreipkitės į sistemos administratorių.',
            { variant: 'error' }
          );
        }
      } else if (request) {
        enqueueSnackbar(
          'Nepavyko pakeisti slaptažodžio. Gali būti, kad prastas interneto ryšys.',
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          'Sistemos klaida. Kreipkitės į sistemos administratorių.',
          { variant: 'error' }
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <div className={classes.root}>
          <Form className={classes.form}>
            <Field
              className={classes.field}
              component={TextField}
              type="text"
              name="email"
              label="El. pašto adresas"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Pakeisti slaptažodį
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ForgotPassword;
