const datagridLocaleText_LT = {
	noRowsLabel: "Nėra duomenų",
	toolbarDensity: "Densyvumas",
	toolbarDensityLabel: "Densyvumas",
	toolbarDensityCompact: "Kompaktiškas",
	toolbarDensityStandard: "Standartinis",
	toolbarDensityComfortable: "Patogus",

	toolbarColumns: "Stulpeliai",
	toolbarColumnsLabel: "Rodyti stulpelius",
	toolbarFilters: "Filtrai",
	toolbarExport: "Eksportuoti",
	toolbarFiltersLabel: "Rodyti filtrus",
	toolbarFiltersTooltipHide: "Slėpti filtrus",
	toolbarFiltersTooltipShow: "Rodyti filtrus",
	toolbarFiltersTooltipActive: (count) =>
		`${count} aktyvus ${count !== 1 ? "filtras" : "filtras"}`,

	checkboxSelectionHeaderName: "Pasirinkti",
	columnMenuLabel: "Meniu",
	columnMenuShowColumns: "Rodyti stulpelius",
	columnMenuFilter: "Filtruoti",
	columnMenuHideColumn: "Slėpti",
	columnMenuUnsort: "Nerūšiuoti",
	columnMenuSortAsc: "Rūšiuoti didėjimo tvarka",
	columnMenuSortDesc: "Rūšiuoti mažėjimo tvarka",
	columnHeaderSortIconLabel: "Rikiuoti",
	columnsPanelTextFieldLabel: "Rasti stulpelį",
	columnsPanelTextFieldPlaceholder: "Stulpelio pavadinimas",

	columnsPanelHideAllButton: "Slėpti visus",
	columnsPanelShowAllButton: "Rodyti visus",

	filterPanelAddFilter: "Pridėti filtrą",
	filterPanelDeleteIconLabel: "Ištrinti",
	filterPanelOperators: "Operatoriai",
	filterPanelOperatorAnd: "Ir",
	filterPanelOperatorOr: "Arba",
	filterPanelColumns: "Stulpeliai",
	filterPanelInputLabel: "Reikšmė",
	filterPanelInputPlaceholder: "Filtro reikšmė",

	filterOperatorContains: "turi",
	filterOperatorEquals: "lygu",
	filterOperatorStartsWith: "prasideda",
	filterOperatorEndsWith: "baigiasi",
	filterOperatorIs: "yra",
	filterOperatorNot: "nėra",
	filterOperatorAfter: "po",
	filterOperatorOnOrAfter: "po arba",
	filterOperatorBefore: "prieš",
	filterOperatorOnOrBefore: "prieš arba",
	filterOperatorIsEmpty: "yra tuščias",
	filterOperatorIsNotEmpty: "nėra tuščias",
	filterOperatorIsAnyOf: "yra bet kuris iš",

	toolbarExportCSV: "Eksportuoti CSV",
	toolbarExportPrint: "Spausdinti",

	MuiTablePagination: {
		labelRowsPerPage: "Eilučių puslapyje:",
		labelDisplayedRows: ({ from, to, count }) =>
			`${from}-${to} iš ${count !== -1 ? count : `daugiau nei ${to}`}`,
	},

	footerRowSelected: (count) =>
		count !== 1
			? `${count.toLocaleString()} eilutės pasirinktos`
			: `${count.toLocaleString()} eilutė pasirinkta`,
};

export default datagridLocaleText_LT;
