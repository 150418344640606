import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import formatDate from '../../utils/formatDate';

const Header = ({ sender, created_at }) => {
  return (
    <div>
      <Typography variant="h4">{sender}</Typography>
      <Typography>{formatDate(created_at)}</Typography>
    </div>
  );
};

Header.propTypes = {
  sender: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
};

export default Header;
