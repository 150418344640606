import axiosInstance from "../axiosInstance";

import { makeAxiosRest } from "../_to_be_published/utils/rest";

export default makeAxiosRest(axiosInstance);

export const endpoints = {
	auth: "/auth/local",
	forgotPassword: "/auth/forgot-password",
	resetPassword: "/auth/reset-password",
	magazines: "/magazines",
	coupons: "/coupons",
	couponsQuery: "/coupons/query",
};
