import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import ButtonSlim from '../ButtonSlim';

const InformationDialog = ({ title, extraActions, children, ...restProps }) => {
  const handleCancelClick = () => restProps.onClose();

  return (
    <Dialog {...restProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        {extraActions}
        <ButtonSlim startIcon={<CloseIcon />} onClick={handleCancelClick}>
          Uždaryti
        </ButtonSlim>
      </DialogActions>
    </Dialog>
  );
};

InformationDialog.propTypes = {
  title: PropTypes.node,
  extraActions: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default InformationDialog;
