import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import rest, { endpoints } from "../../utils/rest";
import "./styles/index.css";
import datagridLocaleText_LT from "./utils/localization";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: theme.spacing(1),
	},
	field: {
		marginBottom: theme.spacing(1),
	},
}));

const COUPONS_QUERY_URL = endpoints.couponsQuery;

const PointsTable = () => {
	const [coupons, setCoupons] = useState([]);
	const [selectedMagazineIds, setSelectedMagazineIds] = useState([]);
	const [selectedMagazineNumbers, setSelectedMagazineNumbers] = useState([]);
	const [selectedMagazineYears, setSelectedMagazineYears] = useState([]);
	const [magazines, setMagazines] = useState([]);
	const [magazineIssues, setMagazineIssues] = useState([]);
	const [magazineReleaseYears, setMagazineReleaseYears] = useState([]);

	const [isLoadingQuery, setIsLoadingQuery] = useState(false);
	const [isLoadingResults, setIsLoadingResults] = useState(false);

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const displayGenericRestError = useCallback(() => {
		enqueueSnackbar(
			"Sistemos klaida. Kreipkitės į sistemos administratorių.",
			{
				variant: "error",
			}
		);
	}, [enqueueSnackbar]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingResults(true);
				const res = await rest.post(COUPONS_QUERY_URL, {
					magazineIds: selectedMagazineIds,
					magazineIssues: selectedMagazineNumbers,
					magazineReleaseYears: selectedMagazineYears,
				});

				setIsLoadingResults(false);
				setCoupons(res);
			} catch (error) {
				displayGenericRestError();
			}
		})();
	}, [
		selectedMagazineIds,
		selectedMagazineYears,
		selectedMagazineNumbers,
		displayGenericRestError,
	]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingQuery(true);
				const res = await rest.get(COUPONS_QUERY_URL);
				setMagazines(
					res.magazines.map((magazine, count) => {
						return { value: magazine.value, id: magazine.id };
					})
				);
				setMagazineIssues(
					res.magazineIssues.map((magazineIssue, count) => {
						return { magazineIssue: magazineIssue, id: count };
					})
				);
				setMagazineReleaseYears(
					res.magazineReleaseYears.map(
						(magazineReleaseYear, count) => {
							return {
								magazineReleaseYear: magazineReleaseYear,
								id: count,
							};
						}
					)
				);
				setIsLoadingQuery(false);
			} catch (error) {
				displayGenericRestError();
			}
		})();
	}, [displayGenericRestError]);

	const stringLengthComparator = (v1, v2) => {
		if (v1.length > v2.length) {
			return 1;
		}
		if (v1.length < v2.length) {
			return -1;
		}
		return v1.localeCompare(v2);
	};

	return (
		<div className={classes.root}>
			<div
				style={{
					height: "30vh",
					width: "100%",
					maxWidth: "1200px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div style={{ height: "90%", width: "30%" }}>
					<DataGrid
						rows={magazines}
						localeText={datagridLocaleText_LT}
						rowsPerPageOptions={[1000]}
						columns={[
							{
								field: "value",
								headerName: "Žurnalo ID",
								disableColumnMenu: true,
								flex: 1,
								headerClassName: "bottom-border-table--header",
							},
						]}
						initialState={{
							sorting: {
								sortModel: [
									{
										field: "value",
										sort: "asc",
									},
								],
							},
						}}
						density="compact"
						checkboxSelection
						onSelectionModelChange={(newSelection) => {
							let temp = [];
							newSelection.forEach((item) => {
								temp.push(item);
							});
							setSelectedMagazineIds(temp);
						}}
						loading={isLoadingQuery}
					/>
				</div>

				<div style={{ height: "90%", width: "30%" }}>
					<DataGrid
						rows={magazineIssues}
						localeText={datagridLocaleText_LT}
						rowsPerPageOptions={[1000]}
						columns={[
							{
								field: "magazineIssue",
								headerName: "Leidimo numeris",
								disableColumnMenu: true,
								flex: 1,
								headerClassName: "bottom-border-table--header",
							},
						]}
						initialState={{
							sorting: {
								sortModel: [
									{
										field: "magazineIssue",
										sort: "asc",
									},
								],
							},
						}}
						density="compact"
						checkboxSelection
						onSelectionModelChange={(newSelection) => {
							let temp = [];
							newSelection.forEach((item) => {
								temp.push(magazineIssues[item].magazineIssue);
							});
							setSelectedMagazineNumbers(temp);
						}}
						loading={isLoadingQuery}
					/>
				</div>

				<div style={{ height: "90%", width: "30%" }}>
					<DataGrid
						rows={magazineReleaseYears}
						localeText={datagridLocaleText_LT}
						rowsPerPageOptions={[1000]}
						columns={[
							{
								field: "magazineReleaseYear",
								headerName: "Metai",
								flex: 1,
								disableColumnMenu: true,
								headerClassName: "bottom-border-table--header",
							},
						]}
						initialState={{
							sorting: {
								sortModel: [
									{
										field: "magazineReleaseYear",
										sort: "asc",
									},
								],
							},
						}}
						density="compact"
						checkboxSelection
						onSelectionModelChange={(newSelection) => {
							let temp = [];
							newSelection.forEach((item) => {
								temp.push(
									magazineReleaseYears[item]
										.magazineReleaseYear
								);
							});
							setSelectedMagazineYears(temp);
						}}
						loading={isLoadingQuery}
					/>
				</div>
			</div>
			<div style={{ height: "55vh", width: "100%", maxWidth: "800px" }}>
				<DataGrid
					rows={coupons}
					localeText={datagridLocaleText_LT}
					columns={[
						{
							// this column is just a row number starting from 1
							field: "rowCount",
							headerName: "Eil. Nr.",
							description: "Eilės numeris",
							headerClassName: "bottom-border-table--header",
							flex: 1,
							sortable: false,
							filterable: false,
							disableColumnMenu: true,
							valueGetter: (params) => {
								return params.api.getRowIndex(params.id) + 1;
							},
						},
						{
							field: "sender",
							headerName: "Siuntėjas",
							description: "Siuntėjo telefono numeris",
							headerClassName: "bottom-border-table--header",
							flex: 1,
							sortable: true,
							filterable: true,
							disableColumnMenu: true,
						},
						{
							field: "magazines",
							headerName: "Žurnalo ID",
							description: "Žurnalo ID",
							headerClassName: "bottom-border-table--header",
							flex: 1,
							sortable: true,
							filterable: true,
							disableColumnMenu: true,
							sortComparator: stringLengthComparator,
						},
					]}
					initialState={{
						sorting: {
							sortModel: [
								{
									field: "magazines",
									sort: "desc",
								},
							],
						},
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					pageSize={20}
					checkboxSelection={true}
					components={{
						Toolbar: GridToolbar,
						LoadingOverlay: LinearProgress,
					}}
					loading={isLoadingResults}
				/>
			</div>
		</div>
	);
};

PointsTable.propTypes = {};

export default PointsTable;
