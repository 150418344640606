import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import AuthProvider from './_to_be_published/components/AuthProvider';
import AppBase from './_to_be_published/components/AppBase';

import reportWebVitals from './reportWebVitals';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <AuthProvider>
        <AppBase>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppBase>
      </AuthProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
